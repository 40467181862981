@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,700&subset=latin-ext');

/* 50 Shades of Grey :-x */

$superGray: #505050;
$niceGreen: #3ab54a;
$betterGreen: #43df58;
$white: #ffffff;
$easyGray: #eaeaea;
$niceRed: #c01d2e;
$niceBlue: #01afee;
$anotherGray: #bfbfbf;
$superGray2: #1c1c1c;
$fiftygray: #f7f7f7;
$almostGray: #111111;
$fourtyGray: #404040;
$breadGray: #a0a0a0;
$efnajn: #f9f9f9;

/* PROFILE */

.profile-card {
  h5 {
    font-size: 18px; }
  h3 {
    font-size: 40px;
    display: inline-block; }
  img {
    position: relative;
    top: -10px;
    padding-right: 8px;
    width: 90px;
    margin-top: 32px; }
  @media(max-width: 767px) {
    text-align: center; } }

/* PROFILE */

/* TOGGLE LOGIN PANEL */

.toggle-login-panel-mobile {
  width: 100%;
  min-height: 65px;
  background-color: $almostGray;
  display: none;
  padding-top: 15px; }

.toggle-login-panel {
  width: 100%;
  min-height: 60px;
  background-color: $almostGray;
  position: absolute;
  top: 80px;
  z-index: 98;
  display: none;
  h5 {
    color: $white;
    font-size: 16px;
    font-weight: 700; } }

.login-panel {
  margin-top: 8px;
  display: inline-block;
  p {
    margin-top: 10px; } }

.logged-dot {
  width: 10px;
  height: 10px;
  background-color: $betterGreen;
  border-radius: 50%;
  position: absolute;
  right: 133px;
  top: 43px; }

/* SEARCHING */

.searching-input {
  border: 0px;
  width: 100%;
  border: 2px solid $anotherGray;
  padding: 10px 15px;
  margin-bottom: 4px;
  &:focus {
    outline: none;
    border: 2px solid $superGray2; } }

/* SEARCHING */

/* NOTIFICATION */

.alert-riko, .alert-bad {
  background-color: $niceGreen;
  height: 56px;
  color: $white;
  text-align: center;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }

.alert-bad {
  background-color: tomato; }

.notification-alert {
  background-color: $niceGreen;
  height: 56px;
  width: 250px;
  color: $white;
  text-align: center;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  position: fixed;
  margin: auto;
  left: 0px;
  right: 0px;
  z-index: 999;
  display: none;
  top: 90px;
  p {
    margin-top: 15px; } }

/* CATEGORY LABELS */

.category-labels {
  .active {
    a {
      border: 2px solid $niceGreen;
      background-color: $white;
      color: $niceGreen;
      font-weight: 700;
      &:hover {
        background-color: $niceGreen;
        color: $white; } } }
  li {
    float: left;
    margin-bottom: 17px;
    a {
      border: 0px;
      font-size: 14px;
      color: $white;
      background-color: $niceGreen;
      border-radius: 30px;
      padding: 8px 16px 9px 16px;
      margin: 0px 3px;
      font-family: 'Ubuntu', sans-serif;
      &:hover {
        background-color: $betterGreen; } } } }

/* END CATEGORY LABELS */

/* CUSTOM INPUT */

.switcher {
  div:first-of-type {
    @media(max-width: 767px) {
      text-align: center;
      padding-bottom: 18px; } }
  div:last-of-type {
    @media(max-width: 767px) {
      text-align: center;
      padding-top: 18px; } } }

.switch-desc {
  padding-top: 7px; }

/**
 * Checkbox Two
 */
.checkboxTwo {
  width: 70px;
  height: 40px;
  background: transparent;
  margin: 20px 60px;
  border-radius: 50px;
  position: relative;
  border: 2px solid $niceGreen;
  @media(max-width: 767px) {
    transform: rotate(90deg); }
  input[type="checkbox"] {
    visibility: hidden; } }

.checkboxTwo:before {
  position: absolute;
  top: 19px;
  left: 14px;
  height: 2px;
  width: 90px;
  background: $superGray2; }


.checkboxTwo label {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -o-transition: all .2s ease;
  -ms-transition: all .2s ease;
  transition: all .2s ease;
  cursor: pointer;
  position: absolute;
  top: 7px;
  z-index: 1;
  left: 7px;
  background: $niceGreen; }

.checkboxTwo input[type=checkbox]:checked + label {
  left: 37px;
  background: $niceGreen; }


/* END CUSTOM INPUT */

/* LOADER */

#loaderik {
  top: -125px; }

.cssload-container {
  position: relative;
  z-index: 50;
  display: none;
  top: -55px; }

.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgb(204,204,204);
  border-left-color: rgb(0,0,0);
  border-radius: 974px {
    -o-border-radius: 974px;
    -ms-border-radius: 974px;
    -webkit-border-radius: 974px;
    -moz-border-radius: 974px; } }

.cssload-whirlpool {
  margin: -24px 0 0 -24px;
  height: 49px;
  width: 49px;
  animation: cssload-rotate 1150ms linear infinite {
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite; } }

.cssload-whirlpool::before {
  content: "";
  margin: -22px 0 0 -22px;
  height: 43px;
  width: 43px;
  animation: cssload-rotate 1150ms linear infinite {
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite; } }

.cssload-whirlpool::after {
  content: "";
  margin: -28px 0 0 -28px;
  height: 55px;
  width: 55px;
  animation: cssload-rotate 2300ms linear infinite {
    -o-animation: cssload-rotate 2300ms linear infinite;
    -ms-animation: cssload-rotate 2300ms linear infinite;
    -webkit-animation: cssload-rotate 2300ms linear infinite;
    -moz-animation: cssload-rotate 2300ms linear infinite; } }

@keyframes cssload-rotate {
  100% {
    transform: rotate(360deg); } }

@-o-keyframes cssload-rotate {
  100% {
    -o-transform: rotate(360deg); } }

@-ms-keyframes cssload-rotate {
  100% {
    -ms-transform: rotate(360deg); } }

@-webkit-keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes cssload-rotate {
  100% {
    -moz-transform: rotate(360deg); } }

/* END LOADER */

body {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  font-size: 16px;
  padding: 0px;
  margin: 0px; }

.riko-heading {
  margin: 0px;
  padding: 0px;
  margin-top: 25px;
  margin-bottom: 20px; }

.my-input {
  border: 0px;
  border: 2px solid $easyGray;
  padding: 10px 15px;
  margin-bottom: 4px;
  &:focus {
    outline: none;
    border: 2px solid $anotherGray; }
  &.login-input {
    border-radius: 30px; } }

#cart-login {
  display: none; }

.red-text {
  color: $niceRed; }

.red-background {
  background-color: tomato; }

.green-text {
  color: #3ab54a; }

.white-text {
  color: $white; }

.almost-gray {
  color: $almostGray; }

.another-gray {
  background-color: $anotherGray !important;
  cursor: default !important; }

.loading-products {
 display: none; }

.bold {
  font-weight: 700; }

.margin-auto {
  margin: 0px auto; }

.is-mobile {
  display: none; }

.is-desktop {
  display: block; }

.filter-arrow {
  background-color: $niceGreen;
  border-radius: 50%;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  top: -2px;
  font-size: 25px;
  i {
    color: $white; }
  &:hover {
    background-color: $betterGreen; } }

.button-my {
  border: 0px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: $white;
  background-color: #a1a1a1;
  padding: 14px 16px 15px 16px;
  display: inline-block;
  font-family: 'Ubuntu', sans-serif;
  &.full-width-always {
    width: 100% !important; }
  &.full-width {
    @media(max-width: 767px) {
      width: 100% !important; } } }
@media(max-width: 480px) {
  .button-my {
    font-size: 12px; } }

.button-my:hover {
  background-color: black;
  color: $white;
  text-decoration: none; }

.delimiter {
  border-top: 1px solid $easyGray !important;
  width: 100%;
  height: 1px; }

.delimiter-vertical {
  width: 1px;
  height: 500px;
  background-color: $easyGray; }

.red-delimiter {
  width: 100%;
  border-top: 3px solid $niceRed; }

.blue-delimiter {
  width: 100%;
  border-top: 3px solid $niceBlue; }

.default-delimiter {
  width: 100%;
  border-top: 3px solid $fourtyGray; }

.black-screen {
  width: 100%;
  height: 300px;
  background-color: #0a0a0a !important; }


.user-panel {
  background: url("../../asset/images/petdays/icon_user.svg") $almostGray 10px no-repeat;
  height: 40px;
  width: 40px;
  background-size: 20px;
  //display: inline-block
  text-align: left;
  float: left; }

.search-panel {
  height: 40px;
  width: 40px;
  background: url("../../asset/images/petdays/icon_search.svg") $fourtyGray 10px no-repeat;
  background-size: 20px; }

/* END HEADER */

/* PRODUCT BOX */

.product-box {
  border: 1px solid $easyGray;
  min-height: 470px;
  padding-top: 30px;
  position: relative;
  margin-bottom: 30px; }

.eshop-category {
  min-height: 250px;
  background-size: 700px !important; }

.not-available {
  position: absolute;
  z-index: 50;
  left: 0px;
  right: 0px;
  top: 100px;
  h4 {
    color: $white;
    font-size: 16px;
    background-color: $niceRed;
    display: inline-block;
    padding: 10px 20px; } }

.product-box:hover {
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.1); }

.product-box > a > h3 {
  font-size: 18px;
  color: $superGray;
  line-height: 1.3; }

.product-box > div > div > div > h4 {
  font-size: 25px;
  color: $superGray;
  font-weight: 700;
  display: inline-block;
  padding: 5px; }

.sale-price {
  color: $niceRed !important; }

.product-box > div > div > div > h5 {
  font-size: 16px;
  text-decoration: line-through;
  color: $anotherGray;
  display: inline-block;
  padding: 5px; }

.product-image {
  background: url("../../asset/images/petdays/product-example.jpg") center center no-repeat;
  height: 200px;
  background-size: contain; }


.add-to-cart-button:focus {
  outline: none; }

.product-box > .sale-label {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: $niceRed;
  padding: 5px 10px;
  z-index: 40; }

.sale-label > p {
  color: $white;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0px;
  margin: 0px; }

.similar-products-name {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  margin-top: 45px; }

/* END PRODUCT BOX */

/* FOOTER */



// .contact
//   width: 270px
//   height: 330px
//   background-color: $superGray2
//   margin: auto
//   margin-top: -5px
//   padding-top: 70px


.information {
  li {
    padding: 4px 0px;
    a {
      color: $superGray2;
      &:hover {
        text-decoration: underline; } } }
  > h3 {}
  margin-top: 25px;
  margin-bottom: 15px; }

.message-box > h3 {
  margin-top: 25px;
  margin-bottom: 15px; }

.message-box > form > input[type="text"] {
  border: 0px;
  width: 100%;
  border: 2px solid $easyGray;
  padding: 10px 15px;
  margin-bottom: 4px;
  &:focus {
    outline: none;
    border: 2px solid $anotherGray; } }

.message-box > form > textarea {
  border: 0px;
  width: 100%;
  height: 120px;
  border: 2px solid $easyGray;
  padding: 10px 15px;
  margin-bottom: 4px;
  &:focus {
    outline: none;
    border: 2px solid $anotherGray; } }

.contact-link {
  background-color: $niceGreen;
  border-radius: 30px;
  padding: 14px 15px 14px 64px;
  background: url("../../asset/images/petdays/icon_footer_phone.svg") no-repeat 8px 5px;
  background-size: 44px;
  background-color: $niceGreen;
  color: $white;
  font-size: 24px;
  font-weight: 700;
  &:after {
    background-color: $niceGreen; }
  &:hover {
    color: $white; } }

.link-contact {
  background: url("../../asset/images/petdays/icon_footer_phone_2.svg") no-repeat;
  height: 65px;
  padding-top: 9px;
  padding-left: 28px; }

.link-email {
  background: url("../../asset/images/petdays/icon_footer_email_2.svg") no-repeat;
  height: 52px;
  padding-top: 11px;
  padding-left: 32px; }

.link-contact > p {
  color: $white;
  font-weight: 700;
  font-size: 23px; }

.link-email > p {
  font-size: 18px;
  font-weight: 700;
  color: $white; }

.email-contact {
  font-size: 19px;
  background: url("../../asset/images/petdays/icon_footer_email.svg") no-repeat 8px 5px $niceGreen;
  background-size: 40px; }

.copyright {
  min-height: 50px;
  width: 100%;
  background-color: $superGray2; }

.copyright > .container > .row > p {
  color: $white;
  font-size: 14px;
  margin-top: 14px; }

/* END FOOTER */

/* DETAIL PRODUCT */

.product-image-detail {
  border: 2px solid $easyGray;
  height: 400px;
  background: url("../../asset/images/petdays/product-example.jpg") no-repeat center center;
  background-size: 200px; }

.detail-product {
 }  //height: 300px

.product-name {
  font-size: 30px; }

.mobile-name {
  display: none; }

.bread-crumb {
  color: $breadGray;
  font-size: 14px !important;
  margin-top: 11px; }

.bread-crumb > a {
  color: $breadGray;
  text-decoration: underline; }

.bread-crumb > a:hover {
  text-decoration: none !important; }

.product-code {
  font-size: 14px;
  color: $superGray; }

.price-green-arrow {
  background: url("../../asset/images/petdays/arrow_green.svg") no-repeat;
  width: 20px;
  height: 10px;
  margin: auto;
  margin-top: -2px; }

.title-black-arrow {
  background: url("../../asset/images/petdays/arrow.svg");
  width: 20px;
  height: 10px;
  margin: auto; }
#secundaryAddres {
  margin-top: 62px; }
.title-black {
  background-color: $superGray2;
  color: $white;
  text-transform: uppercase;
  padding: 9px;
  font-size: 20px;
  > p {
    font-size: 16px;
    text-transform: none;
    font-weight: 300;
    margin-top: 4px;
    margin-right: 18px; } }

.black-label {
  margin-top: 14px;
  > .row {
    > .col-md-12 {
      > .pull-right {
        margin-top: 10px;
        margin-bottom: 5px; } } }
  background-color: $superGray2;
  h3 {
    color: $white;
    text-transform: uppercase;
    padding: 13px;
    font-size: 25px;
    font-weight: 700;
    margin: 0px;
    display: inline-block; }
  p {
    font-size: 16px;
    text-transform: none;
    font-weight: 300;
    margin-top: 4px;
    margin-right: 18px;
    color: $white; } }

.product-in-stock {
  background: url("../../asset/images/petdays/icon_skladom.svg");
  width: 210px;
  height: 45px;
  float: left;
  > p {
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
    padding-left: 34px;
    padding-top: 10px; } }

.not-in-stock {
  background: url("../../asset/images/petdays/icon_objednavka.svg"); }

.add-to-cart-box {
  display: inline-block;
  float: right; }

/* END DETAIL PRODUCT */
.navigation-bar-basket {
  margin-bottom: 20px;
  background-color: #a1a1a1;
  font-size: 20px;
  @media screen and (min-width: 991px) {
    height: 47px; }
  .tab-navigaton {
    a {
      padding: 9px;
      display: block;
      font-weight: 900;
      color: white;
      &:hover {
        text-decoration: none; } }
    .active {
      background-color: #222222;
      color: #fff;
      height: 47px;
      text-decoration: none; } } }
.btns-basket, .block-convert {
  margin-bottom: 70px; }
.basket-products {
  .product-top {
    .media {
      padding: 10px 0;
      border-bottom: 2px solid #eaeaea;
      .media-right {
        width: 50%;
        font-weight: 300;
        font-size: 16px; }
      .price-order-product {
        font-size: 22px;
        margin-left: 35px; }
      .media-heading {
        color: #222222; } }
    .remove-item {
      float: right;
      display: inline-block;
      height: 34px;
      width: 34px;
      border-radius: 20px;
      margin-left: 23px;
      text-align: center;
      margin-top: 4px; } } }
/* SIDE MENU */

.mobile-category {
  width: 100%;
  background-color: $niceGreen;
  display: none;
  > h3 {
    margin: 0px;
    color: $white;
    padding: 16px;
    display: inline-block; }
  > span {
    padding: 19px;
    font-size: 20px; } }

.accordion-menu {
  width: 100%;
  max-width: 300px;
  background: $easyGray;
  position: absolute;
  z-index: 50;
  left: -300px;
  top: 405px;
  a {
    color: $superGray2; } }

.boxik {
  float: left;
  position: absolute;
  z-index: 50;
  top: 405px; }

.boxik:hover {
  cursor: pointer; }

.sidebar-toggle {
  border: 0px;
  padding: 13px 13px !important;
  margin: 0px !important;
  font-size: 20px;
  background-color: $niceGreen;
  border-radius: 0px;
  color: $white; }

.sidebar-name {
  width: 46px;
  height: 125px;
  background-color: $superGray2; }

.sidebar-name > p {
  transform: rotate(90deg);
  color: $white;
  font-weight: 700;
  position: relative;
  top: 35px; }

/* END SIDE MENU */

/* Alfa Omega */

.borderik {
 }  // border: 1px solid black

.news-header {
  height: 500px;
  background: url("../../asset/images/petdays/novinka.jpg") no-repeat center center;
  @media(min-width: 1820px) {
    background-position: -35px 0px; } }

.dog-category-header {
  background: url("../../asset/images/petdays/icon_psy.svg") 50px center no-repeat $niceRed;
  background-size: 350px;
  @media(max-width: 1300px) {
    background-size: 280px; }
  h3 {
    font-size: 64px;
    text-transform: uppercase;
    color: $white;
    font-weight: 700; } }

.dog-category-header-media {
  height: 350px; }

.cat-category-header {
  background: url("../../asset/images/petdays/icon_macky.svg") 50px center no-repeat $niceBlue;
  background-size: 350px;
  @media(max-width: 1300px) {
    background-size: 280px; }
  h3 {
    font-size: 64px;
    text-transform: uppercase;
    color: $white;
    font-weight: 700; } }

.cat-category-header-media {
  height: 350px; }

.category-lighter {
  background-color: $fiftygray;
  height: 150px;
  h5 {
    color: $superGray2; } }

.category-darker {
  height: 150px;
  background-color: $easyGray;
  h5 {
    color: $superGray2; } }

.category-green {
  height: 150px;
  background-color: $niceGreen;
  h5 {
    color: $white;
    font-size: 15px !important;
    font-weight: 700 !important; } }

.frontpage_square {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%; }

.frontpage_square img {
  position: absolute; }

.little-box {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 20px;
  width: 20px;
  background-color: $anotherGray;
  > i {
    position: relative;
    bottom: 2px;
    left: 8px;
    color: $efnajn; } }

.little-category {
  height: 150px;
  h5 {
    font-size: 17px;
    line-height: 1.3; }
  h4 {
    color: $white !important;
    font-weight: 700 !important; } }

/* END Afla Omega */

@media screen and (max-width: 992px) {
  .navbar-nav > li > a {
    font-size: 14px;
    padding: 10px 10px !important; }

  .cat-category-header, .dog-category-header, .cat-category-header-media, .dog-category-header-media {
    height: 250px !important; }

  .delimiter-vertical {
    display: none; } }


@media screen and (max-width: 767px) {

  .is-mobile {
    display: block; }

  .is-desktop {
    display: none; }

  .information {
    display: table;
    margin: auto; }

  .green-arrow {
    display: none; }

  header {
    padding-top: 0px; }

  .main-header {
    display: none; }

  .message-box {
    display: table;
    margin: auto; }

  .cart-box {
    display: none; }

  .mobile-panel {
    display: block; }

  .cd-accordion-menu {
    display: none; }

  .accordion-menu {
    display: block;
    position: static;
    width: 100%;
    max-width: none; }

  .mobile-category {
    display: block; }

  .boxik {
    display: none; }

  .product-name {
    display: none; }

  .mobile-name {
    display: block; }

  .product-in-stock {
    float: none;
    margin: auto; }

  .add-to-cart-box {
    float: none !important;
    margin-top: 30px; } }

/* */
/* END BOOTSTRAP EDIT */

/* CUSTOM EDIT */

.pagination {
  margin: 0px !important; }

.pagination {
  li {
    border-radius: 50%;
    margin: 0px 3px 0px 3px; }
  > li {
    span, a {
      &:hover {
        border-radius: 50%; } }
    > a, span {
      background-color: transparent;
      border: 0px;
      color: $white;
      &:hover {
        background: $betterGreen;
        color: $white; } }
    > a {
      color: $almostGray; } }
  .current {
    padding: 0px;
    background: $niceGreen; } }

.lSSlideOuter {
  .lSPager.lSpg {
    > li {
      &:hover {
        a {
          background: $niceGreen; } } }
    > li.active a {
      background: $niceGreen; } } }

/* END CUSTOM EDIT */

/* ACCORDION MENU */

.menu .active {
  background-color: $niceGreen;
  > a {
    font-weight: 700 !important; }
  a {
    background: transparent;
    color: $white; } }


.menu {
  > li {
    > a {
      line-height: 1.6;
      font-weight: 700; }
    > ul {
        li {
          a {
            font-weight: 300 !important; } } } } }

.is-accordion-submenu-parent > a::after {
  border: 6px inset;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: $superGray2 transparent transparent;
  position: absolute; }

/* PRODUCT CATALOG */

.limit-selector {
  overflow: hidden;
  background-color: #fff;
  background-image: none;
  border-radius: 30px;
  width: 100px;
  border: 1px solid $anotherGray; }

.limit-selector select {
  width: 110%;
  padding: 10px 5px;
  text-align: center;
  background-color: #fff;
  border: 0px; }

.limit-selector select option {
  width: 100%; }

/* END PRODUCT CATALOG */

/* CART */
.total-price {
  padding: 12px 0;
  font-size: 22px;
  color: #a1a1a1;
  strong {
    color: black;
    font-size: 30px; } }
/* LSLIDER */

.lslide {
  margin-right: 0px !important; }
.form-control {
  border-radius: 0;
  height: 40px; }
